// Note: this rule is disabled because config.mode is static but the linter doesn't know that.

import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { User, SAML, OpenID4VC } from 'component/model';

import config from 'utils/config';

const ProviderWrapper = ({ children }) => (
    <GoogleOAuthProvider clientId={window.env.app_google_client_id}>
        <User.Provider>
            <SAML.Provider>
                <OpenID4VC.Provider>{children}</OpenID4VC.Provider>
            </SAML.Provider>
        </User.Provider>
    </GoogleOAuthProvider>
);

export const Provider = ({ children }) => {
    switch (config.mode) {
        default:
            return <ProviderWrapper>{children} </ProviderWrapper>;
    }
};

export const useContext = () => ({
    ...User.useContext(),
    ...SAML.useContext(),
    ...OpenID4VC.useContext(),
});
