// Website mode
const MODES = {
    dev: 'dev',
};

let mode = (window.env.app_mode ?? '').toLowerCase();
if (!MODES[mode]) mode = MODES.dev;

const config = {
    mode,
    MODES,
};

export default config;
