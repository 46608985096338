import * as Utils from './Utils';

const BASE_URL = `${window.env.app_api}/public/${window.env.app_api_version}/${window.env.app_backend_service}/user`;

export const obtainLoginVP = () =>
    Utils.fetcher(`${BASE_URL}/loginwithvc`, { method: 'GET' });

export const readAuthenticatedWithVC = (id) =>
    Utils.fetcher(`${BASE_URL}/readauthenticatedwithvc/${id}`, {
        method: 'GET',
    });

export const exportVC = (preferences) =>
    Utils.fetcher(`${BASE_URL}/exportvc`, {
        method: 'POST',
        body: JSON.stringify({ preferences }),
    });
